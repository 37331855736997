import React, { useState } from "react";
import "./viewer.css";
import attachmentImage from "../../assets/paperclip-solid.svg";


const downloadAttachment = attachment => {
  const link = document.createElement( 'a' );
  link.style.display = 'none';
  document.body.appendChild( link );
  const blob = new Blob( [ attachment.content ], { type: attachment.mimeType } );
  const objectURL = URL.createObjectURL( blob );
  link.href = objectURL;
  link.href = URL.createObjectURL( blob );
  link.download =  attachment.filename;
  link.click();
};

const Viewer = ({mails}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const selectedMail = mails[selectedItemIndex];

  return (
    <div className="container my-5">
      <div className="row">
        <div className="email-color-sender-grey mt-2 ">
          {mails.length} E-Mails
        </div>
        <div className="col-4 email-list-window bg-grey">
          {/* Loop */}
          {mails.map((mail, index) => (
            <div key={index} onClick={() => setSelectedItemIndex(index)} className={selectedItemIndex === index ? "selected-item" : ""}>
              <div className="email-list-item pt-4 pb-4 border-b-grey">
                <div className="email-list-sender email-color-sender-grey fw500">
                  {mail.from?.address}, {mail.from?.name}
                </div>

                <div className="email-list-subject email-color-blue mb-2">
                  {mail.subject}
                </div>

                <div className="email-list-short-description email-color-grey">
                  {/* better css ellipsis*/}
                  {mail.text?.substring(0, 50)}...
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="col-8 email-content-window border-l-grey border-t-grey">
          {selectedMail === undefined
          ? "no mail selected"
          : (
            <>
              <div className="row border-b-grey">
                <div className="col-8 email-information pt-4 pb-5 ms-4">
                  <div className="email-list-sender email-color-sender-grey fw500">
                    From: {selectedMail.from?.address}, {selectedMail.from?.name}
                  </div>
                  <div className="email-list-subject email-color-blue">
                    {selectedMail.subject}
                  </div>
                  <div className="email-list-to email-color-grey mb-2">
                    To: {selectedMail.to?.map(({address, name}) => `${name} (${address})`).join(', ')}
                  </div>
                </div>
                <div className="col-3 email-date pt-4 email-color-grey">
                  <span className="fw500">From:</span> {(new Date(selectedMail.date)).toLocaleDateString()}
                </div>
              </div>
              <div className="row">
              <div className="col-11 mx-2 my-4">
              {selectedMail.text === undefined ?
                <iframe title="email" sandbox style={{width: "100%", height: "600px"}} srcDoc={selectedMail.html} />
                : <pre className="pre-content">
                {selectedMail.text}
                  </pre>
              }

                </div>
              </div>
              <div className="d-flex flex-column align-items-start">
              {selectedMail.attachments.map((attachment, index) =>

              <button className="attachment d-flex flex-row align-items-center" key={index} onClick={() => downloadAttachment(attachment)}>
                <img
                title="Attachment Image"
                width="20px"
                className="pe-2"
                src={attachmentImage}
                alt="mbox Viewer logo"/>
                {attachment.filename}</button>
              )}
              </div>
            </>

          )}
        </div>
      </div>
    </div>
  );
};

export default Viewer;
