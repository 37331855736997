import React from "react";
import Footer from "../footer/Footer";
import Nav from "../nav/Nav";
import "./imprint.css";
import "../../index.css";
import logo from "../../assets/mbox-viewer-logo-words.svg";

const Impressum = () => {
  return (
    <>
      <section id="imprint">
        <div className="container">
          <div className="row">
            <div className="col-12 my-5">
              <a
                href="https://www.mbox-viewer.de/"
                title="mbox-viewer.de"
              >
                <img
                  title="mbox-viewer.de logo"
                  href="https://www.mbox-viewer.de/"
                  src={logo}
                  alt="Logo mbox splitter"
                  width="250px"
                />
              </a>
            </div>
            <div className="col-12 mb-5">
              <h1>Impressum</h1>
              <h2 className="grey">Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
              <p>
                Mergim Rexha
                <br />
                Elberfelderstr. 41
                <br />
                42553 Velbert
              </p>
              <br />

              <h2 className="grey">Kontakt</h2>
              <p>
                Telefon:{" "}
                <a href="tel:+491774950544" title="Call +49 (0) 1774 950 544">
                  +49 (0) 1774 950 544
                </a>{" "}
                <br />
                E-Mail:{" "}
                <a
                  href="mailto:kontakt@mergim-rexha.de?subject=Allgemeine%20Fragen"
                  title="Mail me on kontakt@mergim-rexha.de"
                >
                  kontakt@mergim-rexha.de
                </a>
              </p>
              <br />

              <h2 className="grey">EU-Streitschlichtung</h2>
              <p>
                Die Europ&auml;ische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit:{" "}
                <a
                  href="https://ec.europa.eu/consumers/odr/"
                  title="Visit ec.europa.eu website"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://ec.europa.eu/consumers/odr/
                </a>
                .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </p>
              <br />

              <h2 className="grey">
                Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
              </h2>
              <p>
                Wir sind nicht bereit oder verpflichtet, an
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
              </p>

              <p>
                Quelle:{" "}
                <a
                  href="https://www.e-recht24.de"
                  title="Visit e-recht24 website"
                >
                  e-recht24.de
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Nav />

      <Footer />
    </>
  );
};

export default Impressum;
