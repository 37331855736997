import React from "react";
import "./nav.css";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { BiMessageSquareDetail } from "react-icons/bi";
import { BiBookHeart } from "react-icons/bi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState("#");

  const navigateToIndexWithAnchor = (anchor) => {
    navigate("/" + anchor);
  };

  return (
    <nav>
      <a
        href="#mbox-viewer"
        onClick={() => navigateToIndexWithAnchor("#mbox-viewer")}
        className={activeNav === "#mbox-viewer" ? "active" : ""}
      >
        <AiOutlineHome />
      </a>
      <a
        href="#viewer-about"
        onClick={() => navigateToIndexWithAnchor("#viewer-about")}
        className={activeNav === "#viewer-about" ? "active" : ""}
      >
        <BiBook />
      </a>
      <a
        href="#data-information"
        onClick={() => navigateToIndexWithAnchor("#data-information")}
        className={activeNav === "#data-information" ? "active" : ""}
      >
        <RiServiceLine />
      </a>
      <a
        href="#creation"
        onClick={() => navigateToIndexWithAnchor("#creation")}
        className={activeNav === "#creation" ? "active" : ""}
      >
        <AiOutlineUser />
      </a>
      <a
        href="/blog"
        onClick={() => setActiveNav("/blog")}
        className={activeNav === "/blog" ? "active" : ""}
      >
        <BiBookHeart />
      </a>
      <a
        href="#contact"
        onClick={() => navigateToIndexWithAnchor("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
};

export default Nav;
