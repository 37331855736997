import React, { useState } from "react";
import "./header.css";
import mboxViewerLogo from "../../assets/mbox-viewer-logo-words.svg";
import {splitMBOX} from "../../split-mbox";
import {loadFile} from "../../load-file";
import PostalMime from 'postal-mime';


const Header = ({onSetMails}) => {
  // User input
  const [selectedFile, setSelectedFile] = useState();
  // UI states
  const [isWorking, setIsWorking] = useState(false);
  const [progress, setProgress] = useState();
  const [hasError, setHasError] = useState(false);

  const onFileSelect = async (event) => {
    setIsWorking(true);
    const file = event.target.files[0];
    setSelectedFile(file);
    const mails = [];
    try {
      const fileContent = await loadFile(file, setProgress);
      setProgress('Splitting mbox');
      const parts = splitMBOX(fileContent);
      let number = 0;

      for (const part of parts) {
        number += 1;

        if (number % 50 === 0) {
          setProgress(`${Math.min(number, parts.length)} / ${parts.length} mails`);
        }
        const parser = new PostalMime();
        const result = await parser.parse(part);
        mails.push(result);
      }
    } catch(e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsWorking(false);
      setProgress(undefined);
    }

    onSetMails(mails);

  };

  return (
    <header id="mbox-viewer" className="bg-white">
      {hasError && (
        <div className="alert alert-danger">
          There was an error parsing your mbox file.
        </div>
      )}
      <div className="container d-flex mt-5 justify-content-center flex-column align-items-center text-center">
        <div className="row">
          <div className="col-md-12 mb-4">
            <p className="text-muted mb-1">simple & easy</p>

            <img
              title="mbox splitter logo"
              width="350px"
              src={mboxViewerLogo}
              alt="mbox Viewer logo"
            />
            <h1 className="bigger mt-3 mb-md-0 mb-2 pb-1" style={{ color: "#212529" }}>
              Mbox-Viewer - The best tool to view Mbox Files online!
            </h1>
            <span className="text-muted">I'm the little brother of <a href="https://mbox-splitter.com" title="split your mbox files online">mbox-splitter.com</a></span>
          </div>
        </div>
        <div className="row mb-5 d-flex justify-content-center">
          <div className="col-11 me-0 ms-0 ps-0 pe-0 col-md-12 d-flex text-center mt-md-5 mb-md-5 mb-md-0 justify-content-center upload">
            <label className="uploader-btn flex-column" htmlFor="upload-click-btn">
              {selectedFile === undefined ? (
                "Upload your .mbox file"
              ) : isWorking
              ? <>
                Loading...
                <br/>
                {(progress ? progress : '')}
              </>
              : (
                <>
                  <span style={{ color: "var(--color-green)" }}>
                    {selectedFile.name}
                  </span>
                  <br/>
                <span className="text-new-upload">Click to upload a new mbox file</span>
                </>
              )}
            </label>
            <input
              required
              onChange={onFileSelect}
              type="file"
              accept="application/mbox"
              id="upload-click-btn"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
