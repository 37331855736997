import React from "react";
import "./about.css"; // Assuming you have a stylesheet for the viewer
import mboxViewerPicture from "../../assets/mbox-viewer-picture-to-explain.svg";

const ViewerAbout = () => {
  return (
    <section className="py-5" id="viewer-about">
      <div className="container text-sm-left text-md-center d-flex flex-column justify-content-center">
        <p className="text-muted pt-5 mb-0">about</p>
        <h2>What is Mbox Viewer</h2>
      </div>
      <div className="container pb-5 pt-md-5">
        <div className="row mt-2 align-items-center">
          <div className="col-md-6">
            <span className="fw-bold">
              The{" "}
              <a href="https://mbox-viewer.de" title="Mbox-Viewer">
                Mbox Viewer
              </a>{" "}
              is a user-friendly online tool that allows you to view the contents of mbox files without the need for additional software.
            </span>{" "}
            <br />
            <br />
            If you have a mbox file and want to inspect its contents, the Mbox Viewer simplifies the process. Key features include:
            <ul className="mt-2">
              <li>
                <span className="fw-bold">1.</span> Upload mbox files and easily navigate through the list of emails.
              </li>
              <li>
                <span className="fw-bold">2.</span> View essential details in a concise manner, such as sender, subject, and a brief summary of the email content.
              </li>
              <li>
                <span className="fw-bold">3.</span> Access the complete content of a selected email, including sender, recipient, subject, date, main content, and attachments.
              </li>
            </ul>
            The Mbox Viewer is designed to enhance your experience in exploring mbox files seamlessly through your web browser.
          </div>
          <div className="offset-md-1 col-md-5 pb-sm-3 pb-md-5">
            <div className="border-0 text-center">
              <img
                title="Mbox Viewer Picture"
                width="340px"
                src={mboxViewerPicture}
                alt="mbox-viewer"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewerAbout;
