import React, { useEffect, useState } from "react";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Viewer from "./components/viewer/Viewer";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import DataInformation from "./components/dataInformation/DataInformation";
import Creation from "./components/creation/Creation";
import Howto from "./components/howto/Howto";
import Me from "./components/me/Me";
import Contact from "./components/contact/Contact";
import Imprint from "./components/imprint/Imprint";
import Privacy from "./components/privacy/Privacy";
import Blog from "./components/blog/Blog";
import BlogItem from "./components/blogItem/BlogItem";

import "bootstrap/dist/css/bootstrap.css";
import {
  Route,
  Routes,
  BrowserRouter,
  Outlet,
  useLocation,
} from "react-router-dom";

const Index = () => {

  const [mails, setMails] = useState([]);
  const onSetMails = (mails) => {
    setMails(mails);
    document.getElementById("js-mail-viewer").scrollIntoView();
  }
  return <>
    <Header onSetMails={onSetMails} />
    <div id="js-mail-viewer">
        {mails.length !== 0 && <Viewer mails={mails} />}
    </div>
    <Nav />
    <About />
    <DataInformation />
    <Creation />
    <Howto />
    <Me />
    <Contact />
    <Footer />
    </>;
};

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <main>
      <Outlet />
    </main>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Index />} />
          <Route path="/blog"  >
            <Route index element={<Blog />} />
            <Route path=":slug" element={<BlogItem />} />
          </Route>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/imprint" element={<Imprint />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
