import React from "react";
import "./howto.css";

const About = () => {
return (
<section className="py-5" id="howto">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-7">
        <p className="text-muted pt-5 text-left mb-0">How to</p>
        <h2>How can I view my Mbox File online?</h2>
      </div>
    </div>

    <div className="pb-5 d-flex flex-column align-items-center">
      <div className="row justify-content-center pt-4">
        <div className="col-md-7 text-left">
          <p>
            Mbox files are a common format for saving emails that is used by various email clients. With the Mbox
            Viewer, users can conveniently view their Mbox files online without having to install special software. This
            guide will walk you through the simple process of uploading your Mbox file and show you how to view and
            search your emails clearly. Follow the steps below to view your Mbox file online using the Mbox Viewer:
          </p>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-md-7">
          <h2>Step-by-Step Guide to Viewing Mbox Files Online</h2>
          <ul className="mt-2">
            <li>
              <span className="fw-bold">1.</span> <strong>Upload Mbox file:</strong>
              Click on the upload button and select your Mbox file from your
              computer.
            </li>
            <li>
              <span className="fw-bold">2.</span> <strong>Wait for processing:</strong> The Mbox Viewer starts
              processing your file immediately,
              directly in your own browser, using JavaScript only. Your file will not be processed or saved on a
              server or anywhere else. A progress bar informs you about the current status of processing. Please be
              patient while your emails are being prepared.
            </li>
            <li>
              <span className="fw-bold">3.</span> <strong>Clear display:</strong> The display is divided into two
              columns, similar to conventional
              e-mail programs such as Mail from Mac or Thunderbird. On the left, you will see a list of emails that
              you can click on. This overview contains information such as sender, subject, and a short excerpt from
              the content of the email. On the right-hand side, you will then find the entire content of the
              respective email, including sender, date sent, subject, recipient, message text, and any attachments
              that are displayed at the end.
            </li>
          </ul>
          <strong>With the Mbox viewer, you can view your emails quickly and easily online, no matter where you are. Use
            these instructions to view your .mbox file online.</strong>
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        <div className="col-md-7">
          <h2>Split your .mbox files with our <a href="mbox-splitter.com" title="mbox-splitter">mbox-splitter.com</a>
          </h2>
          <span className="text-muted">The mbox-viewer is the little brother of the mbox-splitter</span>
          <p>Do you need help splitting your mbox files? Then simply visit our mbox-splitter.com to split your files
            quickly and easily. You can then view them conveniently here in Mbox-Viewer.de. My goal is to provide a
            one-stop solution for all your mbox file requirements. If you still have a topic or potential ideas, feel free to <a href="mailto:kontakt@mergim-rexha.de" title="contact me">contact</a> me at any time.</p>
        </div>
      </div>

    </div>

  </div>
</section>
);
};

export default About;