import React from "react";
import Footer from "../footer/Footer";
import Nav from "../nav/Nav";
import "./blog.css";
import "../../index.css";
import logo from "../../assets/mbox-viewer-logo-words.svg";
import { Helmet } from "react-helmet";
import { getTeaser, getTitle, slugs } from "./blog-items";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>Blog - All about mbox-viewer and E-Mails</title>
        <meta
          name="description"
          content="Learn more about mbox-viewer and E-Mails on our blog page"
        />
      </Helmet>
      <section id="blog">
        <div className="container">
          <div className="row">
            <div className="col-12 my-5">
              <a
                href="https://www.mbox-viewer.de/"
                title="mbox-viewer.de"
              >
                <img
                  title="mbox-viewer.de logo"
                  href="https://www.mbox-viewer.de/"
                  src={logo}
                  alt="Logo mbox splitter"
                  width="250px"
                />
              </a>
            </div>
          </div>
          <div className="row mb-5 d-flex justify-content-center">
            <div className="col-12 mb-5">
              <h1 class="blog-page-headline text-center">
                Blog - All about mbox-splitter and E-Mails{" "}
              </h1>
            </div>
            {slugs.map((slug) => {
              const title = getTitle(slug);
              return (
                <div key={slug} className="col-10 blog-item">
                  <h2>
                    <Link to={slug} title={`Go to ${title}`}>
                      {title}
                    </Link>
                  </h2>
                  <p>{getTeaser(slug)}</p>
                  <Link
                    to={slug}
                    title={`Read more about ${title}`}
                    className="blog-button"
                  >
                    read more ↠
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Nav />

      <Footer />
    </>
  );
};

export default Blog;
