import React from "react";
import "./me.css";
import me from "../../assets/mergim-rexha-profilbild.jpg";

// DO NOT USE THE IMAGES IN PRODUCTION

const Me = () => {
  return (
    <section className="py-5" id="me">
      <div className="container text-center d-flex flex-column justify-content-center">
        <p className="text-muted pt-5 mb-0">Founder</p>
        <h2 className="pb-3">This is me</h2>
      </div>
      <div className="container pb-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 text-center">
            <img
              title="Picture of the founder"
              src={me}
              className="img-founder"
              width="130px"
              alt="mergim rexha founder"
            />
          </div>
          <div className="col-md-6 text-center pt-5">
            <p>
              Since I was 21 years old, I have been developing my own projects
              with full enthusiasm. When I put an idea or a goal in my head, it
              doesn't give me a moment's peace until I've put that thought into
              action. To name a problem is right. Working on it is important.
              Solving it is crucial.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Me;
