import React from "react";
import Footer from "../footer/Footer";
import Nav from "../nav/Nav";
import "./blogItem.css";
import "../../index.css";
import logo from "../../assets/mbox-viewer-logo-words.svg";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { useBlogItem } from "../blog/blog-items";

const BlogItem = () => {
  const { slug } = useParams();
  const { title, content, description } = useBlogItem(slug);

  return (
    <>
      <Helmet>
        <title>Blog - {title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <section id="blogItem">
        <div className="container">
          <div className="row">
            <div className="col-12 my-5">
              <a
                href="https://www.mbox-viewer.de/"
                title="mbox-viewer.de"
              >
                <img
                  title="mbox-viewer.de logo"
                  href="https://www.mbox-viewer.de/"
                  src={logo}
                  alt="Logo mbox splitter"
                  width="250px"
                />
              </a>
            </div>
          </div>
          <div className="row mb-5 d-flex justify-content-center">
            <div className="col-8 mb-5 text-center">
              <h1 className="blog-page-headline">{title}</h1>
            </div>
            <div className="col-8 text-align-justify mb-4">
              <p>{content}</p>
              <div className="mt-5 d-flex justify-content-between">
                <Link to="/blog" class="blog-button" title="back to the blog">
                  ↞ Back to the blog
                </Link>
                <Link to="/" class="blog-button" title="to the Mbox Splitter">
                  Mbox-Splitter ↠
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Nav />

      <Footer />
    </>
  );
};

export default BlogItem;
