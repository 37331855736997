import React from "react";
import "./creation.css";

// DO NOT USE THE IMAGES IN PRODUCTION

const Creation = () => {
return (
<section className="py-5" id="creation">
  <div className="container text-md-center d-flex flex-column justify-content-center pb-3">
    <p className="text-muted pt-5 mb-0">Story of mbox viewer</p>
    <h2>
      Why{" "}
      <a href="https://mbox-viewer.de" title="mbox-viewer">
        mbox-viewer.de
      </a>{" "}
      exists
    </h2>
  </div>
  <div className="container pb-5">
    <div className="row pt-md-5">
      <div className="col-md-6">
        <h3>How it started</h3>
        <p>
          It all started with the development of the <a href="https://mbox-splitter.com" title="mbox splitter">Mbox
            splitter</a>. At the time, I was looking for a solution that could
          split my <a href="https://en.wikipedia.org/wiki/Mbox" title="mbox wikipedia">Mbox files</a> easily and
          conveniently. But I couldn't find anything suitable and quickly developed a
          script that I executed locally via my terminal. It worked perfectly and I wondered if I was alone with this
          problem. After doing some research, I realized that there was a lot of demand, but there was no good solution.
          After some time, I was contacted to see if it was possible to <a href="https://mbox-viewer.de"
            title="view mbox files online">view Mbox files online</a>. I hadn't thought about
          this idea before. Why would anyone want to view their Mbox files online? However, it turned out that there
          were good reasons for doing so. So I decided to make the little brother of Mbox-Splitter.com available to the
          world as well.
        </p>
      </div>
      <div className="col-md-6 pt-5 pt-md-0">
        <h3 className="ps-4">Why <a href="https://mbox-viewer.de" title="mbox-viewer.de">mbox-viewer.de</a></h3>
        <ul>
          <li><strong>Flexibility and accessibility:</strong> You can view your Mbox files online, no matter where you
            are. As long as you have an internet connection, you can access your emails without being tied to specific
            devices or email clients. All you need is the Mbox file.</li>
          <li><strong>No software installation required:</strong> Unlike other providers, you don't need to install any
            special software to view your Mbox files online. Simply upload your Mbox file and the Mbox viewer will do
            the rest. Simply open your web browser and you can view your emails without any additional effort.</li>
          <li><strong>Platform independence:</strong> Whether you use Windows, macOS, Linux or even a mobile operating
            system like iOS or Android - you can easily access your emails by viewing them online. All you need is a web
            browser.</li>
          <li><strong>Quick access to archived emails:</strong> When you view Mbox files online, you can quickly access
            archived emails that are stored in your files. You don't have to open the files locally to access these
            important messages.</li>
        </ul>
      </div>
    </div>
  </div>
</section>
);
};

export default Creation;