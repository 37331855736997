export const splitMBOX = (mboxContents) => {
  mboxContents = mboxContents.replace(/\r(?!\n)/g, "\n");

  let allMailsString;
  if (/^From(?!:)/.test(mboxContents)){
    allMailsString = mboxContents;
  } else {
    const firstResult = mboxContents.match(/(\n|\r)From(?!:)/);
    if (firstResult === null) {
      throw new Error("Could not find mbox entry");
    }
    // + 1 for the newline
    allMailsString =  mboxContents.substring(firstResult.index + 1);
  }
  const entries = [];
  let lastStartPosition = 0;
  while (true) {
    if (allMailsString.length <= lastStartPosition ){
      break;
    }
    const nextMailResult = allMailsString.substring(lastStartPosition).match(/(\n|\r)From(?!:)/);

    if (nextMailResult === null) {
      entries.push(allMailsString.substring(lastStartPosition, allMailsString.length));
      break;
    }
    const currentMail = allMailsString.substring(lastStartPosition, lastStartPosition + nextMailResult.index + 1)
    entries.push(currentMail);
    lastStartPosition += nextMailResult.index + 1;
  }
  return entries;
}
