export const loadFile = async (file, setProgress) => {
  const splitSize = 50;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    let allMails = '';
    let offset = 0;
    reader.onload = (event) => {
      const chunk = reader.result;
      const decoder = new TextDecoder("utf-8");
      allMails += decoder.decode(chunk);
      offset += event.target.result.byteLength;

      next();
    };

    reader.onerror = function () {
      reject(reader.error.message);
    };

    const chunkSize = 1000 * 1000 * splitSize; // 5mb

    const next = () => {
      console.log(allMails);
      if (offset >= file.size) {
        resolve(allMails);
        return;
      }
      setProgress(`Reading - ${Math.floor((offset / file.size) * 100)}%`);
      const slice = file.slice(offset, offset+ chunkSize);
      reader.readAsArrayBuffer(slice);
      offset += chunkSize;
    };
    next();
  });
};
